<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
  import * as echarts from 'echarts';
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    timesList:{
      type: Array,
      default(){
        return [

        ]
      }
    },
    profitList:{
      type: Array,
      default(){
        return [

        ]
      }
    },
    copyList:{
      type: Array,
      default(){
        return [

        ]
      }
    },
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose();
    this.chart = null
  },
  watch:{
    copyList(newVal,oldVal){
      if(newVal){
        this.setOption();
      }
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOption();
    },
    setOption(){
      this.chart.setOption( {
        legend: {
          left: '5%',
          show: true,
          data: ['跟单数','盈亏']
        },
        tooltip: {},
        xAxis: {
          show: false,
          type: 'category',
          data: this.timesList,
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name:'跟单数',
            data: this.copyList,
            type: 'line',
            smooth: true,
          },
          {
            name:'盈亏',
            data: this.profitList,
            type: 'bar'
          },
        ]
      })
    }
  }
}
</script>
