import request from '@/utils/request'

export function getSiteToitalData(params) {
  return request({
    url: 'community/crm/querySiteInfo',
    method: 'get',
    params
  })
}


export function querySiteWalletInfo(params) {
  return request({
    url: 'community/crm/querySiteWalletInfo',
    method: 'get',
    params
  })
}


export function querySiteDosageInfo(params) {
  return request({
    url: 'community/crm/querySiteDosageInfo',
    method: 'get',
    params
  })
}

export function querySiteProfitInfo(params) {
  return request({
    url: 'community/crm/querySiteProfitInfo',
    method: 'get',
    params
  })
}
