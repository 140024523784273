<template>
  <div class="wallet-content referrer">
    <el-table
      size="mini"
      :data="data"
      :header-cell-style="{ background: '#f1f3f5',color: '#333333' }"
      @sort-change="sortChange"
    >
      <el-table-column
        label="排名"
        prop="rank"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="推荐人"
        prop="userName"
      >
        <template slot-scope="scope">
          <span class="user-name">{{scope.row.userName}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="推荐人数"
        sortable
        prop="childrens"
      >
      </el-table-column>
      <el-table-column
        label="名下交易员"
        sortable
        prop="masterNumber"
      >
      </el-table-column>
      <el-table-column
        label="名下跟随者"
        sortable
        prop="copyNumber"
      >
      </el-table-column>
      <el-table-column
        label="收入贡献"
        sortable
        prop="amount"
      >
      </el-table-column>
    </el-table>
    <div class="paging-outer display-flex flex-end">
      <el-pagination
        :total="total"
        style="margin-top: 20px;"
        small
        layout="prev, pager, next"
        @size-change="sizeChange"
        @current-change="pageChange"/>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import initData from '@/mixins/initData'
  export default {
    name: 'referrerList',
    mixins: [initData],
    mounted() {
      this.$nextTick(() => {
        this.init()
      })
    },
    computed: {
      ...mapGetters(['siteId']),
    },
    methods: {
      beforeInit() {
        this.url = 'community/crm/querySiteRefereesInfo'
        this.params = {
          siteId: this.siteId,
          page: this.page,
          size: this.size,

        }
        if(this.sort){
          this.params.sort = this.sort;
        }
        return true
      },
    }
  }
</script>

<style lang="less" scoped>
.referrer {
  height: 460px!important;
  .user-name {
    color: #66b1ff;
  }
}
</style>
