<template>
<div class="panel app-container">
  <el-row :gutter="20">
    <el-col :span="8">
      <div class="site-info">
        <div class="panel-subheader">
          <span>站点</span>
          <div class="header-right site-config">
            <span class="days">已运行{{siteInfo.effective}}天</span>
            <span class="to-time">有效期至{{parseDate(siteInfo.endTime,'{YY-MM-dd}')}}</span>
          </div>
        </div>
        <div class="site-content">
          <p class="sitename">{{siteInfo.siteName || 'pabalatrade'}}</p>
          <p class="domain">{{siteInfo.domainName || 'www.pabalatrade.com'}}</p>
        </div>
      </div>
    </el-col>
    <el-col :span="16">
      <div class="user-data">
        <div class="panel-subheader">
          <span>系统用量</span>
        </div>
        <div class="user-data-content">
          <div class="total">
            <div class="icon-box">
              <i class="el-icon-user icon-el"></i>
            </div>
            <div class="data-box">
              <p class="data-top">总客户</p>
              <p class="data-bot">{{siteInfo.customerNumber}}</p>
            </div>
          </div>
          <div class="trader">
            <div class="data-box">
              <p class="data-top">交易员账户</p>
              <p class="data-bot">{{siteInfo.masterNumber}}</p>
            </div>
          </div>
          <div class="follow">
            <div class="data-box">
              <p class="data-top">跟随者账户</p>
              <p class="data-bot">{{siteInfo.copyNumber}}</p>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :lg="6" :xs="24" :sm="12" :md="12">
      <div class="income">
        <div class="panel-subheader">
          <span>社区收入</span>
        </div>
        <div class="income-content">
          <circular-ring
            height="400px"
            :pie-chart="pieData"
          ></circular-ring>
        </div>
      </div>
    </el-col>
    <el-col :lg="6" :xs="24" :sm="12" :md="12">
      <div class="wallet">
        <div class="panel-subheader">
          <span>钱包资产</span>
        </div>
        <div class="wallet-content">
          <circular-ring-two
            height="400px"
            :pie-chart="pieData"
          ></circular-ring-two>
        </div>
      </div>
    </el-col>
    <el-col :lg="12" :xs="24" :sm="24" :md="24">
      <div class="use-number">
        <div class="panel-subheader">
          <span>用量统计</span>
          <div class="user-right">
            <span class="right-tag"
              v-for="(item,index) in tagList"
                  :key="index"
                  :class="{active: currentTag == item.value}"
                  @click="tagHandle(item)"
            >{{item.label}}</span>
          </div>
        </div>
        <div class="wallet-content lineandbar-content">
          <bar-user
            :times-list="barUser.times"
            :client-list="barUser.customers"
            :master-list="barUser.masters"
            :copy-list="barUser.masters"
          ></bar-user>
          <el-row class="lineandbar-row">
            <el-col :span="8">
              <div class="line-user-label">客户</div>
              <div class="line-user-data">{{barUser.customerNum}}</div>
            </el-col>
            <el-col :span="8">
              <div class="line-user-label">交易员账户</div>
              <div class="line-user-data">{{barUser.masterNum}}</div>
            </el-col>
            <el-col :span="8">
              <div class="line-user-label">跟随者账户</div>
              <div class="line-user-data">{{barUser.copyNum}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :lg="12" :xs="24" :sm="24" :md="24">
      <div class="use-number">
        <div class="panel-subheader">
          <span>跟单盈亏统计</span>
          <div class="user-right">
            <span class="right-tag"
                  v-for="(item,index) in tagCopyList"
                  :key="index"
                  :class="{active: currentCopyTag == item.value}"
                  @click="tagCopyHandle(item)"
            >{{item.label}}</span>
          </div>
        </div>
        <div class="wallet-content lineandbar-content copye-content">
          <bar-user-copy
            :times-list="barUserCopy.times"
            :profit-list="barUserCopy.profits"
            :copy-list="barUserCopy.copyNums"
          ></bar-user-copy>
          <el-row class="lineandbar-row">
            <el-col :span="8">
              <div class="line-user-label">跟单数</div>
              <div class="line-user-data">{{barUserCopy.copyNum}}</div>
            </el-col>
            <el-col :span="8">
              <div class="line-user-label">盈亏</div>
              <div class="line-user-data">{{barUserCopy.profitNum}}</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-col>
    <el-col :lg="12" :xs="24" :sm="24" :md="24">
      <div class="use-number">
        <div class="panel-subheader">
          <span>推荐人排名</span>
        </div>
        <referrer-list></referrer-list>
      </div>
    </el-col>
  </el-row>
</div>
</template>

<script>
  import referrerList from '@/components/panel/referrerList';
  import barUserCopy from '@/components/panel/barUserCopy';
  import barUser from '@/components/panel/barUser';
  import circularRingTwo from '@/components/panel/circularRingTwo'
  import circularRing from '@/components/panel/circularRing'
  import { mapGetters } from 'vuex'
  import { parseDate } from '../../utils'
  import { getSiteToitalData,querySiteWalletInfo,querySiteDosageInfo,querySiteProfitInfo } from '@/api/panel'
  export default {
    name: 'index',
    components:{
      circularRing,
      circularRingTwo,
      barUser,
      barUserCopy,
      referrerList,
    },
    data() {
      return {
        siteInfo: {
          effective: '',//	整型	Y	运行时间
          siteName:'',//	字串	Y	站点名称
          domainName:'',//	字串	Y	域名
          copyNumber: 0, //	整型	Y	跟随账户数
          copyMaxNumber: 0,//	整型	Y	最大跟随账户数
          masterNumber: 0, //	整型	Y	交易员账户数
          masterMaxNumber: 0, //	整型	Y	最大交易员账户数
          customerNumber: 0, //	整型	Y	客户数
          accountNumber: 0,//	整型	Y	账户数
          endTime: 0,//	时间戳	Y	有效期
        },
        tagList:[
          {
            value: 1,
            label: '近7天',
          },
          {
            value: 2,
            label: '近1月',
          },
          {
            value: 3,
            label: '近3月',
          },
          {
            value: 0,
            label: '全部',
          }
        ],
        tagCopyList:[
          {
            value: 1,
            label: '近7天',
          },
          {
            value: 2,
            label: '近1月',
          },
          {
            value: 3,
            label: '近3月',
          },
          {
            value: 0,
            label: '全部',
          }
        ],
        currentTag: 1,
        currentCopyTag: 1,
        pieData:{
          masterAmount: 0,
          siteAmount: 0,
          commissionAmount: 0,

          balance: 0,
          applyAmount: 0,
          outAmount: 0,
        },
        barUser:{
          timeList: [],
          clientList: [],
          masterList: [],
          copyList: [],
          customerNum: 0,
          masterNum: 0,
          copyNum: 0,
        },
        barUserCopy:{
          times: [],
          profits: [],
          copyNums: [],
          copyCount: 0,
          profitCount: 0,
        }
      }
    },
    computed: {
      ...mapGetters(['siteId']),
    },
    watch:{
      siteId(newValue){
        if(newValue){
          this.getSiteInfo(newValue);
        }
      }
    },
    created(){
      this.getSiteInfo(this.siteId);
      this.getPieInfo(this.siteId);
      this.getDosageInfo(this.siteId);
      this.getProfitInfo(this.siteId);
    },
    methods: {
      parseDate,
      getSiteInfo(siteId) {
        getSiteToitalData({
          siteId:siteId,

        }).then((res) => {
          this.siteInfo = res;
        })
      },
      getPieInfo(siteId) {
        querySiteWalletInfo({
          siteId:siteId
        }).then((res) => {
          this.pieData = res;
        })
      },
      getDosageInfo(siteId) {
        querySiteDosageInfo({
          siteId:siteId,
          timeType: this.currentTag
        }).then((res) => {
          this.barUser = res;
        })
      },
      getProfitInfo(siteId) {
        querySiteProfitInfo({
          siteId:siteId,
          timeType: this.currentCopyTag
        }).then((res) => {
          console.log(res);
          this.barUserCopy = res;
        })
      },
      tagHandle(item){
        this.currentTag = item.value;
        this.getDosageInfo(this.siteId);
      },
      tagCopyHandle(item){
        this.currentCopyTag = item.value;
        this.getProfitInfo(this.siteId);
      }
    }
  }
</script>

<style lang="less" scoped>
  p {
    padding: 0;
    margin: 0;
  }
.panel {
  background-color: #f1f3f5;
  color: #333333;
  .site-info,
  .user-data,
  .income,
  .wallet,
  .use-number {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .site-info,
  .user-data {
    height: 180px;
  }
  .panel-subheader {
    height: 60px;
    border-bottom: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    align-items: center;
    > span {
      font-weight: 600;
      font-size: 16px;
    }
    .site-config {
      padding-right: 20px;
      .days,
      .to-time {
        font-size: 8px;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: #f1f3f5;
        color: #666666;
      }
    }

  }
  .site-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sitename {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
    }
    .domain {
      font-size: 14px;
      color: #5D9EF8;

    }
  }
  .site-content,
  .user-data-content{
    height: calc(100% - 40px);
  }
  .user-data-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .total {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .icon-box {
        width: 48px;
        height: 48px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: rgba(93,158,248,.2);
        color: #5D9EF8;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-el {
          font-size: 24px;
        }
      }
    }
    p {
      text-align: center;
      color: #333333;
      font-size: 20px;
      &.data-top {
        font-size: 14px;
        color: #999999;
        margin-bottom: 10px;
      }
      &.data-bot {
        font-weight: bold;
      }
    }
  }
  .wallet-content,
  .income-content {
    height: 400px;
  }
  .use-number {
    .user-right {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      align-items: center;
      border: 1px solid #E5E5E5;
      text-align: center;
      border-radius: 5px;
      overflow: hidden;
      .right-tag {
        padding: 10px 18px;
        border-right: 1px solid #E5E5E5;
        font-size: 10px;
        cursor: pointer;
        color: #666666 ;
        &:last-child {
          border-right: none;
        }
        &.active {
          background-color: #5D9EF8 ;
          color: #ffffff;
        }
      }
    }
  }
  .lineandbar-content {
    padding-top: 20px;
    .lineandbar-row {
      padding: 0 40px;
      .line-user-label {
        color: #9C9C9C;
        margin-bottom: 10px;
      }
      .line-user-data {
        font-weight: bold;
      }
    }
  }
  .copye-content {
    height: 460px;
  }
}
</style>
