<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
  import * as echarts from 'echarts';
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    pieChart:{
      type: Object,
      default(){
        return {
          outAmount: 0,
          applyAmount: 0,
          balance: 0,
        }
      }
    },
  },
  data() {
    return {
      chart: null
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose();
    this.chart = null
  },
  watch:{
    pieChart(newVal,oldVal){
      if(newVal){
        this.setOption();
      }
    }
  },
  computed: {
    totalAmount(){
      return this.pieChart.outAmount + this.pieChart.applyAmount + this.pieChart.balance;
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOption();
    },
    setOption(){
      this.chart.setOption( {
        title: {
          text: '总收入',
          subtext: `$${this.totalAmount}`,
          left: 'center',
          top: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        color: ['#6AB687','#DB6E6A','#DDDFE1'],
        label: {
          alignTo: 'edge',
          minMargin: 20,
          edgeDistance: 15,
          lineHeight: 28,
          formatter: function(data) {
            if(data.dataIndex == 0) {
              return '{name1|'+data.data.name+'}\n{time|'+data.data.value+'}';
            } else if(data.dataIndex == 1) {
              return '{name2|'+data.data.name+'}\n{time|'+data.data.value+'}';
            } else {
              return '{name3|'+data.data.name+'}\n{time|'+data.data.value+'}';
            }
          },
          rich: {
          },
        },
        series: [
          {
            name: '钱包资产',
            type: 'pie',
            radius: ['40%', '50%'],
            data: [
              { value: this.pieChart.outAmount, name: '已支付' },
              { value: this.pieChart.applyAmount, name: '待支付' },
              { value: this.pieChart.balance, name: '剩余未转出金额' },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
    }
  }
}
</script>
